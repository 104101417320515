import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="58e065195064f7a07a230d39e7e5b7e73f45de42"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="gallery flexslider">
          <ul className="slides">
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_04.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>STRESS ET DIARRHEE</h2>
                    <p>
                      Nous connaissons tous des situations stressantes, dans
                      notre vie professionnelle ou privée. Pourtant, rares sont
                      ceux qui savent que le stress et la nervosité peuvent
                      déclencher <strong>une diarrhée</strong>. Apprenez-en
                      davantage sur les causes du{" "}
                      <strong>stress et de la diarrhée</strong>, ainsi que sur
                      ce que vous pouvez faire.
                    </p>
                    <a
                      href="/fr/la-diarrhee/stress-et-diarrhee/"
                      className="button radius"
                    >
                      Stress et diarrhée
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_03.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>CONSEILS EN CAS DE DIARRHEE</h2>
                    <p>
                      En ce qui concerne le
                      <strong> traitement de la diarrhée</strong>, il subsiste
                      de nombreux malentendus et incertitudes.
                      <br />
                      Vous trouverez ici quelques conseils concernant le
                      traitement de la diarrhée.
                    </p>
                    <a
                      href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/"
                      className="button radius"
                    >
                      Conseils en cas de diarrhée
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_02.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>COMMENT AGIT IMODIUM®&nbsp; </h2>
                    <p>
                      La&nbsp;<strong>vidéo </strong>suivante vous montre
                      comment<strong> la diarrhée</strong> prive votre corps de
                      liquides et électrolytes importants, et comment{" "}
                      <strong>IMODIUM®</strong> aide en cas de diarrhée.
                    </p>
                    <a
                      href="/fr/comment-agit-imodium/"
                      className="button radius"
                    >
                      Comment agit IMODIUM®&nbsp;{" "}
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="img">
                <img
                  src="/assets/files/gallery/images/bg_01.jpeg"
                  width="1664"
                  height="510"
                />
              </div>
              <div className="row">
                <div className="six columns">
                  <div className="slide-caption">
                    <h2>LA DIARRHEE EN VOYAGE </h2>
                    <p>
                      Le temps des vacances est pour la plupart des gens le
                      meilleur moment de l'année. Personne ne veut passer ces
                      précieuses journées dans sa chambre d'hôtel avec{" "}
                      <strong>la diarrhée</strong> . Pour cette raison, vous
                      trouverez ici quelques conseils utiles et des informations
                      sur la prévention et le traitement des symptômes de{" "}
                      <strong>diarrhée lorsque vous voyagez</strong>.
                    </p>
                    <a href="/fr/diarrhee-en-voyage/" className="button radius">
                      La diarrhée du voyageur
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="row gallery-nav">
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>STRESS ET DIARRHEE
            </a>
          </div>
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>CONSEILS EN CAS DE DIARRHEE
            </a>
          </div>
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>COMMENT AGIT IMODIUM®&nbsp;{" "}
            </a>
          </div>
          <div className="three columns">
            <a href="#" className="active">
              <span className="arrow">&nbsp;</span>LA DIARRHEE EN VOYAGE{" "}
            </a>
          </div>
          <div className="three columns indicator" />
        </div>
        <div className="main row">
          <div className="six columns">
            <div className="spotlight row">
              <div className="six columns">
                <h2>
                  <a href="#" className="playVideo" id>
                    SPOT
                    <br /> IMODIUM<sup>®</sup>{" "}
                  </a>
                  {/*<a href="#" id>
                    SPOT-TV
                    <br /> IMODIUM<sup>®</sup>{" "}
                  </a>*/}
                </h2>
                <p>
                  Regardez le spot IMODIUM<sup>®</sup>{" "}
                </p>
              </div>
              <div className="six columns">
                <div className="img">
                  <a className="playVideo" id href="#">
                    <img
                      src="/assets/img/KeyVisual_Traffic Jam_FR.jpg"
                      width="248"
                      height="139"
                    />
                  </a>
                  {/* <a id href="#">
                    <img
                      src="/assets/img/spotlight.jpg"
                      width="248"
                      height="139"
                    />
                  </a> */}
                </div>
                <a href="#" className="badge highlight playVideo" id>
                  <span>
                    Regardez
                    <br />
                    le film
                  </span>
                  <span className="arrow">&gt;</span>
                </a>
              </div>
            </div>
            <div className="spotlight row">
              <div className="six columns">
                <h2>
                  <a href="/fr/diarrhee-en-voyage/eviter-la-diarrhee-du-voyageur/">
                    LA DIARRHÉE EN VOYAGE
                  </a>
                </h2>
                <p>
                  Découvrez des conseils pratiques pour éviter la diarrhée en
                  voyage
                </p>
              </div>
              <div className="six columns">
                <div className="img">
                  <a href="/fr/diarrhee-en-voyage/eviter-la-diarrhee-du-voyageur/">
                    <img
                      src="/assets/img/home-widget-reisfolder.jpg"
                      width="248"
                      height="139"
                    />
                  </a>
                </div>
                <a
                  href="/fr/diarrhee-en-voyage/eviter-la-diarrhee-du-voyageur/"
                  className="badge highlight"
                >
                  <span>
                    Voir
                    <br />
                    plus
                  </span>
                  <span className="arrow">&gt;</span>
                </a>
              </div>
            </div>
            <div className="spotlight row">
              <div className="six columns">
                <h2>
                  <a href="/fr/faq-questions-diarrhee/">FOIRE AUX QUESTIONS</a>
                </h2>
              </div>
              <div className="six columns">
                <p>
                  Vous trouverez ici les questions les plus fréquentes
                  concernant la diarrhée et IMODIUM<sup>®</sup>.
                </p>
                <a href="/fr/faq-questions-diarrhee/">Voir plus</a>
              </div>
            </div>
          </div>            
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/IMODIUM TRAFFIC JAM+TAG PHARMACIE_FR15_181220.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
